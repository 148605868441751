let cache = {}
let detailedCache = {}

export default class CourseService {
  constructor(svc) {
    this.svc = svc
  }

  async getCoursesBy(location, reload = false) {
    if ((location in cache) && !reload) return this.getCachedLocation(location)
    try {
      let res = await this.svc.getCoursesBy(location)
      cache[location] = res
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getCoursesDetailedBy(location) {
    try {
      let res = await this.svc.getCoursesDetailedBy(location)
      detailedCache[location] = res
      return res
    } catch (error) {
      return Promise.reject(error)
    }
  }

  getCachedLocation(location) {
    return cache[location]
  }
}
