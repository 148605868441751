<script>
import api from "@/services/api/api-courses"
import Svc from "@/app/admin/models/courseService"
import CachedSvc from "@/app/admin/models/courseServiceCached"
import CourseModel from '../../../../models/courseModel'

export default {
  name: 'CourseChooseProvider',

  render() {
    return this.$scopedSlots.default({
      hasCourseLocation: this.hasCourseLocation,
      loadCoursesDatesByLocation: this.loadCoursesDatesByLocation,
      loading: this.loading,
      courses: this.courses
    })
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    svc: new CachedSvc(
      new Svc(api),
    ),
    loading: false,
    localCourses: []
  }),

  computed: {
    hasCourseLocation() {
      return !!this.item.course.location;
    },
    courses() {
      return this.item.course.ID ? [...this.localCourses] : this.localCourses
    }
  },

  mounted() {
    if (this.item.course.city) return this.loadData(this.item.course.city)
    if (this.item.course.city === null) return this.loadData('null')
  },

  methods: {
    async loadCoursesDatesByLocation(newLocation) {
      let course = new CourseModel()
      course.city = newLocation
      this.$emit('input:course', course)
      this.loadData(newLocation)
    },

    async loadData(newLocation) {
      try {
        this.loading = true
        const cachedLocation = this.svc.getCachedLocation(newLocation)
        if (cachedLocation && cachedLocation.find(({ID}) => ID === this.item.course.ID)) {
          this.localCourses = cachedLocation
        } else {
          this.localCourses = await this.svc.getCoursesBy(newLocation, true)
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>
